import React from "react";
import { useSelector } from "react-redux";
import { PrismicRichText } from "@prismicio/react";

import Layout from "../../layout";
import {
  useHomePageData,
  RegisterDemoAccount,
} from "../../prismic/staticQueries";
import TradingPlatforms from "../../components/register/trading-platforms";
import FormToggle from "../../components/register/form-toggle";
import DemoForm from "../../components/register/demo-form";

import { getRedirection } from "../../state/reducers/Auth";
import BANNER from "../../images/siteImages/registerDemo/REGISTER_DEMO_BANNER.jpg";

const RegisterLive = (props) => {
  const urlParams = new URLSearchParams(props.location.search);
  // const referral = urlParams.get('ref');
  const language = useSelector((state) => state.language);
  getRedirection(urlParams, props.location);
  const homePageData = useHomePageData(language);
  const registerDemoAccount = RegisterDemoAccount(language);

  const formWrap = {
    borderRadius: "10px",
    paddingBottom: "50px",
    width: "100%",
    height: "100%",
  };

  const bg = {
    overflow: "hidden",
    width: "100%",
    backgroundRepeat: "no-repeat",
    minHeight: "980px",
    backgroundImage: "url(" + BANNER + ")", //for new

    // backgroundImage: "url("+signUpBg+")",

    backgroundPosition: "bottom center",
  };

  return (
    <Layout>
      <>
        <div style={bg}>
          <section className="content-block">
            <div className="container">
              <div className="row marTop40">
                <div className="col-lg-7">
                  <div style={{ width: "100%", marginBottom: "20px" }}>
                    <div
                      style={{
                        fontSize: "30px",
                        color: "#244a88",
                        fontWeight: "bold",
                      }}
                    >
                      <PrismicRichText
                        field={registerDemoAccount.form_bg_content_1.richText}
                      />
                    </div>
                  </div>

                  <div className="marTop40" style={{ width: "100%" }}>
                    <div style={{ color: "#1a1a1a" }}>
                      <div style={{ fontSize: "18px", fontWeight: "200" }}>
                        <PrismicRichText
                          field={registerDemoAccount.form_bg_content_2.richText}
                        />
                      </div>
                      <div style={{ fontSize: "12px" }}>
                        <PrismicRichText
                          field={registerDemoAccount.form_bg_content_3.richText}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div id="div-register" style={formWrap}>
                    <FormToggle MainComponent={DemoForm} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <TradingPlatforms
          {...homePageData}
          {...registerDemoAccount}
          lang={language}
        />
      </>
    </Layout>
  );
};

export default RegisterLive;
